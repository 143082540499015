( function ( app ) {
    'use strict';

    let scriptDependencies = [];

    /**
     * Loads in JS dependencies for widgets based on data-script markers in the DOM
     * @param  {HTMLElement} element - DOM scope (defaults to full document)
     */
    app.widgetDeps = ( element = document ) => {
        const scriptMarkerElements = [].slice.call( element.querySelectorAll( '[data-script]' ) );
        scriptMarkerElements.forEach( scriptMarkerElement => {
            const dependencies = scriptMarkerElement.dataset.script.split( ',' ).map(
                scriptString => scriptString.trim()
            );
            dependencies.forEach( dependency => _addDependency( dependency ) );
        } );
    };

    /**
     * Script injection function based on fixed format file names
     * @param  {String} dependency - the fixed format for the file (i.e., SITENAME_WIDGETFOLDERNAME)
     */
    const _addDependency = dependency => {
        if ( scriptDependencies.indexOf( dependency ) < 0 ) {
            scriptDependencies.push( dependency );
            let script = document.createElement( 'script' );
            script.type = 'text/javascript';
            script.src = _getScriptSrc( dependency );
            document.body.appendChild( script );
        }
    };

    /**
     * Builds the script source path
     * @param  {String} dependency - the script name
     * @return {String}            - the full path
     */
    const _getScriptSrc = dependency => {
        const scriptPrefix = app.environment.cdn + 'widgets';
        const scriptSuffix = app.environment.label !== 'dev' ? 'min.js' : 'js';
        return `${ scriptPrefix }/${ dependency }.${ scriptSuffix }`;
    };

    /**
     * If the document's already loaded and everything's ready to go,
     * load in the depedencies straight away. This is chiefly used to load
     * dependencies for(dynamically) later-rendered widget markup.
     *
     * If not, wait for the event and then load the dependencies. This will
     * be the most common scenario, for when the dependency manager is
     * triggered on app load.
     */
    switch ( document.readyState ) {
        case 'interactive':
        case 'complete':
            app.widgetDeps();
            break;
        default:
            document.addEventListener( 'DOMContentLoaded', () => {
                app.widgetDeps();
            } );
    }

}( PULSE.app ) );
