( function( app ) {
    'use strict';

    /**
     * Map of media query size labels to screen widths
     * Mirrors what's in src/styles/includes/layout/_mq.scss so the JS & CSS use the same values
     */
    app.measurements = {
        mobile: 400,
        phablet: 640,
        tablet: 840,
        desktop: 1025,
        wide: 1300
    };
}( PULSE.app ) );
