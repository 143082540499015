/*globals PULSE, PULSE.app */

( function( app ) {
    'use strict';

    /**
     * Static component that should be used to initialise one or more widgets on the page
     */
    app.widgetInitialiser = {};

    /**
     * Adds a single instance of the given widget (will replace any existing instances)
     * @param {String} widgetMarker the name of the data-widget attribute to query for in order to find the widget
     * @param {Object} widgetConstructor optional, the class to create a new instance of for the
     *  widget. If this is not set, it will be derived from the widgetMarker
     */
    app.widgetInitialiser.addWidget = ( widgetMarker, widgetConstructor ) => {
        let Widget = widgetConstructor || app[ getConstructorName( widgetMarker ) ];
        let widgetContainer = document.querySelector( '[data-widget="' + widgetMarker + '"]' );

        if ( widgetContainer && widgetContainer.getAttribute( 'data-initialised' ) !== 'true' ) {
            app.widgets[ widgetMarker ] = new Widget( widgetContainer );
            widgetContainer.setAttribute( 'data-initialised', true );
        }
    };

    /**
     * Creates an array of widgets for every instance of the HTML marker that is found on the page
     * @param {String} widgetMarker the name of the data-widget attribute to query for in order to find the widgets
     * @param {Object} widgetConstructor optional, the class to create a new instance of for the
     *  widget. If this is not set, it will be derived from the widgetMarker
     * @param {DOMElement} element optional override (instead of querying the whole document)
     */
    app.widgetInitialiser.addMultipleWidgetsByName = ( widgetMarker, widgetConstructor, element ) => {
        let queryRoot = element || document;
        let Widget = widgetConstructor || app[ getConstructorName( widgetMarker ) ];
        let widgetContainers = queryRoot.querySelectorAll( '[data-widget="' + widgetMarker + '"]' );

        for ( var i = 0; i < widgetContainers.length; i++ ) {
            createWidget( widgetMarker, widgetContainers[ i ], Widget );
        }
    };

    /**
     * Looks for all data-widget attributes in the given DOM element (or document) and creates
     * instances for each of these if they haven't been initialised already. Initialised widgets
     * will have data-initialised="true" on their data-widget DOM element.
     *
     * A data-constructor can be set to define the name of the constructor within the 'app'
     * namespace (e.g., app.MatchCentreWidget would be data-constructor="MatchCentreWidget". If
     * this is not set, it will be derived from the data-widget attribute:
     * data-widget="match-centre" becomes MatchCentreWidget
     *
     * @param {DOMElement} element optional override (instead of querying the whole document)
     */
    app.widgetInitialiser.addWidgets = element => {
        let queryRoot = element || document;
        let widgetContainers = queryRoot.querySelectorAll( '[data-widget]' );

        for ( var i = 0; i < widgetContainers.length; i++ ) {
            let constructorName = widgetContainers[ i ].getAttribute( 'data-constructor' );
            let widgetMarker = widgetContainers[ i ].getAttribute( 'data-widget' );

            if ( !constructorName && widgetMarker ) {
                constructorName = getConstructorName( widgetMarker );
            }

            if ( app.hasOwnProperty( constructorName ) ) {
                createWidget( widgetMarker, widgetContainers[ i ], app[ constructorName ] );
            }
        }
    };

    /**
     * Adds an instance of a given widget to the widget array stored in the app
     * @param {String} widgetMarker the name of the data-widget attribute to query for in order to find the widgets
     * @param {DOMElement} widgetContainer the DOM element for the widget
     * @param {Object} WidgetConstructor the class to create a new instance of for the widget
     */
    const createWidget = ( widgetMarker, widgetContainer, WidgetConstructor ) => {
        if ( widgetContainer.getAttribute( 'data-initialised' ) !== 'true' ) {
            if ( !app.widgets[ widgetMarker ] ) {
                app.widgets[ widgetMarker ] = [];
            }
            app.widgets[ widgetMarker ].push( new WidgetConstructor( widgetContainer ) );
            widgetContainer.setAttribute( 'data-initialised', true );
        }
    };

    /**
     * Converts the widget marker name into a Pulse App widget class name
     * e.g., "match-centre" to MatchCentreWidget
     * @param {String} widgetMarker the widget marker name (data-widget attribute value)
     * @return {String} the transformed CamelCase string of the widget class
     */
    const getConstructorName = widgetMarker => {
        return widgetMarker.toLowerCase().replace( /(\b|-)\w/g, function( marker ) {
            return marker.toUpperCase().replace( /-/, '' );
        } ) + 'Widget';
    };

}( PULSE.app ) );
