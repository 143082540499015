/*eslint no-console: ["error", { allow: ["warn", "error", "info"] }] */
( function( app ) {
    'use strict';

    app.templating = {};
    app.templating.helper = {};


    /**
     * Renders a template with the given data and returns the compiled template
     *
     * @param {Object}  model             - data to render in JSON format
     * @param {String}  templateName      - the name of the template (must match file name)
     * @param {Boolean} parseToHtmlObject - parse the rendered template string to an HTML object - default false
     * @return {(String|DOMElement)}      - Rendered template with model
     */
    app.templating.render = function( model = {}, templateName, parseToHtmlObject ) {
        let renderedTemplate = '';

        for ( var func in app.templating ) {
            if ( app.templating.hasOwnProperty( func ) ) {
                model[ func ] = app.templating[ func ];
            }
        }

        // attach app env to the main underscore template
        model.environment = app.environment;
        model.helper.template = app.common.template;

        if ( templateName ) {
            var templateEngine = app.templates[ templateName ];
            if ( templateEngine ) {
                try {
                    renderedTemplate = templateEngine( model );
                } catch ( error ) {
                    if ( error.message ) {
                        error.message += ' in template ' + templateName;
                    }
                    console.warn( error );
                    console.info( 'Model given', model );
                }
                if ( parseToHtmlObject ) {
                    return stringToElement( renderedTemplate );
                }
            } else {
                console.warn( 'No template was rendered. Template not found: ' + templateName );
            }
        }
        return renderedTemplate;
    };

    /**
     * Parses an HTML string to an actual element
     *
     * @param {String} htmlString - HTML string you want to parse to an HTML element
     * @return {DOMElement} - HTMLstring cast to a DOMElement
     */
    const stringToElement = htmlString => {
        let element = document.createElement( 'div' );
        element.innerHTML = htmlString.trim();
        return element.firstChild;
    };

    /**
    * @param {Array} tags - an array of tags the article has
    * @return {String} single tag name or defaults to 'news'
    */
    app.templating.helper.manageTags = function manageTags( tags ) {
        //news is default
        var articleTag = 'news';

        if ( tags.length > 0 ) {
            tags.forEach( function( tag ) {
                if ( tag.label !== 'news' ) {
                    articleTag = tag.label;
                }
            } );
        }

        return articleTag;
    };

}( PULSE.app ) );
