/* eslint-disable no-labels */
( function( app, i18n ) {
    'use strict';

    app.account = 'expedia-atout';
    app.templates = {};
    app.common = {};
    app.defaultLanguage = 'EN';
    app.language = i18n.language;
    app.widgets = {};

    app.paths = [
        {
            label: 'dev',
            api: '//api.dev.expedia.pulselive.com',
            domain: [ 'atout.dev.expedia.pulselive.com' ],
            cdn: '/resources/ver/',
            cms: '//api.dev.expedia.pulselive.com/content/expedia-atout',
            hotelListingApiRegions: '//hotel-listing.dev.expedia.pulselive.com/hotellisting/regions/',
            hotelListingApiHotels: '//hotel-listing.dev.expedia.pulselive.com/hotellisting/hotels/',
            mapDomain: 'map.atout.dev.expedia.pulselive.com'
        },
        {
            label: 'test',
            api: '//api.test.expedia.pulselive.com',
            domain: [ 'atout.test.expedia.pulselive.com' ],
            cdn: `/resources/${ window.RESOURCES_VERSION }/`,
            cms: '//api.test.expedia.pulselive.com/content/expedia-atout',
            hotelListingApiRegions: '//hotel-listing.test.expedia.pulselive.com/hotellisting/regions/',
            hotelListingApiHotels: '//hotel-listing.test.expedia.pulselive.com/hotellisting/hotels/',
            mapDomain: 'map.atout.test.expedia.pulselive.com'
        },
        {
            label: 'prod',
            api: '//api.expedia.pulselive.com',
            domain: [ 
                'atout.expedia.pulselive.com',
                'auvergnerhonealpes.expedia.fr',
                'auvergnerhonealpes.expedia.co.uk'
             ],
            cdn: `/resources/${ window.RESOURCES_VERSION }/`,
            cms: '//api.expedia.pulselive.com/content/expedia-atout',
            hotelListingApiRegions: '//hotel-listing.expedia.pulselive.com/hotellisting/regions/',
            hotelListingApiHotels: '//hotel-listing.expedia.pulselive.com/hotellisting/hotels/',
            mapDomain: 'map.atout.expedia.pulselive.com'
        }
    ];

    app.checkEnvironment = () => {
        const location = window.location.hostname;
        let environment;

        pathloop: for ( let i = 0; i < app.paths.length; i++ ) {

            const path = app.paths[ i ];

            if ( Array.isArray( path.domain ) ) {
                for ( let j = 0; j < path.domain.length; j++ ) {
                    const domain = path.domain[ j ];
                    if ( location === domain || location.match( new RegExp( domain ) ) ) {
                        // set the current domain on the path instead of the array
                        path.domain = domain;
                        environment = path;
                        break pathloop;
                    }
                }
            } else if ( location === path.domain || location.match( new RegExp( path.domain ) ) ) {
                environment = path;
                break;
            }
        }

        return environment || 'There are no app.paths defined for this domain';
    };

    app.cookiePrefix = 'expedia-gc_';

    app.essentialCookies = [
        {
            name: 'cookie-privacy_policy',
            expiry: '100'
        },
        {
            name: 'store-all-cookies',
            expiry: '100'
        },
        {
            name: 'base-web_test-cookie',
            expiry: '7'
        }
    ];

    app.environment = app.checkEnvironment();

    app.apiPath = {
        cms: {
            text: app.environment.cms + '/text/' + app.language + '/',
            photo: app.environment.cms + '/photo/' + app.language + '/',
            promo: app.environment.cms + '/promo/' + app.language + '/',
            playlist: app.environment.cms + '/playlist/' + app.language + '/'
        }
    };

    const DOMReady = () => {
        app.I18N.setup();

        /**
         * Polyfill any object-fit images (for IE11) - if you want to target a specific class pass it as a parameter.
         * NB: This needs to be used in conjunction with the SASS mixin in the mixins.scss file
         */
        objectFitImages();
    };

    document.addEventListener( 'DOMContentLoaded', DOMReady );

}( PULSE.app, PULSE.I18N ) );
