/* globals PULSE.core */
/* eslint id-length: ["error", { "exceptions": ["LT", "LTS", "l", "L", "ll",
 * "LL", "lll", "LLL", "llll", "LLLL" ] }] */
/* eslint no-console: ["error", { allow: ["warn"] }] */

( function( app, core ) {
    'use strict';

    app.I18N = {};

    app.I18N.setup = () => {
        const reqLanguage = core.localStorage.getStorage( 'req_language', true );
        app.language = reqLanguage || app.defaultLanguage;
        let Translator = new app.I18N.Translator( PULSE.I18N );

        if ( !PULSE.I18N ) {
            PULSE.I18N = {};
        }

        PULSE.I18N.lookup = function lookup() {
            return Translator.lookup.apply( Translator, arguments );
        };

        PULSE.I18N.lookupKey = function lookupKey() {
            return Translator.lookupKey.apply( Translator, arguments );
        };

        if ( typeof window.moment !== 'undefined' ) {
            app.I18N.enOverride();
            moment.locale( app.language );
        }
    };

    app.I18N.enOverride = () => {
        moment.locale( 'en', {
            longDateFormat: {
                LT: 'HH:mm',
                // LT: "h:mm A",
                LTS: 'h:mm:ss A',
                l: 'DD/MM',
                L: 'DD/MM/YYYY',
                ll: 'D MMMM',
                LL: 'D MMMM YYYY',
                lll: 'MMM D YYYY LT',
                LLL: 'MMMM Do YYYY LT',
                llll: 'ddd MMM D YYYY',
                LLLL: 'dddd, MMMM Do YYYY'
            },
            yearFirst: false
        } );
    };


    app.I18N.Translator = function Translator( translationsData ) {
        let _self = this;

        _self.hasTranslations = false;
        _self.language = app.language;

        if ( translationsData ) {
            _self.hasTranslations = true;
            if ( _self.language !== translationsData.language ) {
                if ( typeof translationsData.language === 'undefined' ) {
                    console.warn( 'Language mismatch! Using ' + translationsData.language );
                }
                _self.language = translationsData.language;
            }

            _self.translations = translationsData.translations || {};
            _self.hasTranslations = true;
        } else {
            _self.hasTranslations = false;
            _self.translations = {};
        }
    };

    app.I18N.Translator.prototype.lookup = function lookup( key, replaceMap = {} ) {
        let _self = this;

        if ( key && replaceMap ) {
            let mapping = _self.lookupKey( key );

            for ( let replaceKey in replaceMap ) {
                if ( {}.hasOwnProperty.call( replaceMap, replaceKey ) ) {
                    let regExp = new RegExp( '\\${' + replaceKey + '}', 'gi' );
                    mapping = mapping.replace( regExp, replaceMap[ replaceKey ] );
                }
            }

            return mapping;
        }

        return '';
    };

    app.I18N.Translator.prototype.lookupKey = function lookupKey( key ) {
        var _self = this;
        if ( _self.hasTranslations ) {
            return _self.translations[ key ] || key;
        }

        return key;

    };

}( PULSE.app, PULSE.core ) );
